<template>
	<div>
		<el-form
			ref="queryForm"
			class="query-box"
			v-model="queryParams"
			:inline="true"
		>
			<el-form-item label="创建时间" prop="name" label-width="120px">
				<el-date-picker
					size="small"
					v-model="createDateRange"
					type="daterange"
					@change="dateChange"
					range-separator="-"
					value-format="yyyy-MM-dd"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				>
				</el-date-picker>
			</el-form-item>
			<el-form-item
				label="商户编号"
				prop="merchantNo"
				label-width="120px"
			>
				<el-input
					v-model="queryParams.merchantNo"
					clearable
					placeholder="请输入商户编号"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item
				label="代扣订单"
				prop="acqWithholdingNo"
				label-width="120px"
			>
				<el-input
					v-model="queryParams.acqWithholdingNo"
					clearable
					placeholder="代扣订单"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item label="代扣类型" prop="name" label-width="120px">
				<el-select
					v-model="queryParams.withholdingType"
					placeholder="请选择代扣类型"
					size="small"
					style="width: 240px"
				>
					<el-option label="全部" value=""></el-option>
					<el-option label="押金" value="deposit"></el-option>
					<el-option label="流量卡" value="flow_card"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="代扣状态" prop="name" label-width="120px">
				<el-select
					v-model="queryParams.withholdingStatus"
					placeholder="请选择交易状态"
					size="small"
					style="width: 240px"
				>
					<el-option label="全部" value=""></el-option>
					<el-option label="初始化" value="INIT"></el-option>
					<el-option label="代扣中" value="withholding"></el-option>
					<el-option label="已完成" value="success"></el-option>
					<el-option label="已取消" value="cancel"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="备注" prop="remark" label-width="120px">
				<el-input
					v-model="queryParams.remark"
					clearable
					placeholder="请输入备注"
					style="width: 240px"
					size="small"
					@keyup.enter.native="handleQuery"
				/>
			</el-form-item>
			<el-form-item label="完成时间" prop="name" label-width="120px">
				<el-date-picker
					size="small"
					v-model="updateDateRange"
					type="daterange"
					@change="dateChange"
					range-separator="-"
					value-format="yyyy-MM-dd"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
				>
				</el-date-picker>
			</el-form-item>
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button
					type="primary"
					icon="el-icon-search"
					size="mini"
					@click="handleQuery"
					v-permission="'WITHHOLDING_ORDER_QUERY'"
				>
					查询
				</el-button>
				<el-button
					icon="el-icon-refresh"
					size="mini"
					@click="handleResetQuery"
					v-permission="'WITHHOLDING_ORDER_QUERY'"
				>
					重置
				</el-button>
				<el-button
					icon="el-icon-download"
					size="mini"
					@click="handleExport"
					v-permission="'AGENT_ACCOUNT_EXPORT'"
				>
					导出
				</el-button>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { FinancialToolsApi } from "@/api";
export default {
	name: "ToolBar",
	props: {
		dicts: {
			type: Object,
			default: {}
		}
	},
	data() {
		return {
			queryParams: {},
			createDateRange: [],
			updateDateRange: [],
			showCustomForm: false
		};
	},
	mounted() {},
	methods: {
		handlerCustomFormOnclose() {
			this.showCustomForm = false;
			this.commitChange();
		},
		handleQuery() {
			this.commitChange();
		},
		handleResetQuery() {
			this.queryParams = {};
			this.createDateRange = [];
			this.updateDateRange = [];
			this.commitChange();
		},
		handleAdd() {
			this.showCustomForm = true;
		},
		handleExport() {
			this.$confirm("确定导出代扣订单", "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(async () => {
				this.dealWithQueryParams();
				let result = await FinancialToolsApi.withholdingOrder.export(
					this.queryParams
				);
				if (result.success) {
					this.downloadFile(result.data);
				}
			});
		},
		dealWithQueryParams() {
			let [startCreateTime, endCreateTime] = this.createDateRange;
			this.queryParams.startCreateTime =
				startCreateTime && startCreateTime + " 00:00:00";
			this.queryParams.endCreateTime =
				endCreateTime && endCreateTime + " 23:59:59";

			let [startCompleteTime, endCompleteTime] = this.updateDateRange;
			this.queryParams.startCompleteTime =
				startCompleteTime && startCompleteTime + " 00:00:00";
			this.queryParams.endCompleteTime =
				endCompleteTime && endCompleteTime + " 23:59:59";
		},
		commitChange() {
			this.dealWithQueryParams();
			let params = { ...this.queryParams };
			this.$emit("on-change", params);
		}
	}
};
</script>

<style scoped></style>
